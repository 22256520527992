<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpathRoadmapExtraAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="gpathRoadmapExtraObjList" csv-title="gpath_roadmap_extra" v-if="gpathRoadmapExtraObjList && gpathRoadmapExtraObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
              <b-col sm="8" md="8" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getgpathRoadmapExtraDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpathRoadmapExtraObjList && gpathRoadmapExtraObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpathRoadmapExtraObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="gpathRoadmapExtraEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showGPathrRoadmapExtraDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathrRoadmapExtraAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <GPathrRoadmapExtraAdd :propOpenedInModal="true" @emitCloseGPathrRoadmapExtraAddModal="closeGPathrRoadmapExtraAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpathRoadmapExtraAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathrRoadmapExtraAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathrRoadmapExtraEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <GPathrRoadmapExtraEdit :propOpenedInModal="true" :propGPathrRoadmapExtraObj="gpathRoadmapExtraEditObj" @emitCloseGPathrRoadmapExtraEditModal="closeGPathrRoadmapExtraEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpathRoadmapExtraEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathrRoadmapExtraEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathrRoadmapExtraDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathrRoadmapExtraDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpathRoadmapExtraDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathrRoadmapExtras } from "../../../FackApi/api/GPathrRoadmapExtra.js"
import GPathrRoadmapExtraAdd from "./GPathrRoadmapExtraAdd"
import GPathrRoadmapExtraEdit from "./GPathrRoadmapExtraEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GPathrRoadmapExtraList",
  components: {
    GPathrRoadmapExtraAdd,
    GPathrRoadmapExtraEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_roadmap_extra_list",
      cvModuleFile: "gpath_roadmap_extra",
      cvModulePrefix: "GPRME",
      cvCardTitle: "GPaths Roadmap Extras",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPathrRoadmapExtra",
      cvAddModalHeader: "Add GPathrRoadmapExtra",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathrRoadmapExtra List Response",
      showModelGPathrRoadmapExtraAdd: false,
      showModelGPathrRoadmapExtraEdit: false,
      showModelGPathrRoadmapExtraDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "name", key: "name", class: "text-left align-text-top", sortable: true },
        { label: "name key", key: "name_key", class: "text-left align-text-top", sortable: true },
        { label: "title", key: "title", class: "text-left align-text-top", sortable: true },
        { label: "description", key: "description", class: "text-left align-text-top", sortable: true },
        { label: "url", key: "url", class: "text-left align-text-top", sortable: true },
        { label: "image", key: "image", class: "text-left align-text-top", sortable: true },
        { label: "career name", key: "career_name", class: "text-left align-text-top", sortable: true },
        { label: "career key", key: "career_key", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      gpathRoadmapExtraObjList: null,
      gpathRoadmapExtraEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getDateFilter()
    this.gpathRoadmapExtraList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getgpathRoadmapExtraDateWise () {
      this.gpathRoadmapExtraList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * gpathRoadmapExtraList
     */
    async gpathRoadmapExtraList () {
      try {
        let gpathRoadmapExtraListResp = await GPathrRoadmapExtras.gpathRoadmapExtraList(this, this.whereFilter)
        if (gpathRoadmapExtraListResp.resp_status) {
          this.gpathRoadmapExtraObjList = gpathRoadmapExtraListResp.resp_data.data
          this.totalRows = gpathRoadmapExtraListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathRoadmapExtraListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpathRoadmapExtraList() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraAdd
     */
    gpathRoadmapExtraAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpathRoadmapExtra_add")
        }
        else {
          this.showModelGPathrRoadmapExtraAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraAdd() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraEdit
     */
    gpathRoadmapExtraEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpathRoadmapExtra_edit/" + this.gpathRoadmapExtraEditObj.gprme_id)
        }
        else {
          this.gpathRoadmapExtraEditObj = item
          this.showModelGPathrRoadmapExtraEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathrRoadmapExtraDeleteDialog
     */
    showGPathrRoadmapExtraDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathrRoadmapExtraDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathrRoadmapExtraDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraDelete
     */
    async gpathRoadmapExtraDelete () {
      try {
        let gpathRoadmapExtraDelResp = await GPathrRoadmapExtras.gpathRoadmapExtraDelete(this, this.delObj.gprme_id)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapExtraDelResp)

        if (gpathRoadmapExtraDelResp && !gpathRoadmapExtraDelResp) {
          this.showModelGPathrRoadmapExtraDelete = false
          return false
        }

        let index = this.gpathRoadmapExtraObjList.indexOf(this.delObj)
        this.gpathRoadmapExtraObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathrRoadmapExtraDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathrRoadmapExtraAddModal
     */
    closeGPathrRoadmapExtraAddModal (gpathRoadmapExtraAddData) {
      try {
        if (gpathRoadmapExtraAddData) {
          if (this.gpathRoadmapExtraObjList && this.gpathRoadmapExtraObjList.length >= 1) {
            let gpathRoadmapExtraObjLength = this.gpathRoadmapExtraObjList.length
            let lastId = this.gpathRoadmapExtraObjList[gpathRoadmapExtraObjLength - 1]["id"]
            gpathRoadmapExtraAddData.id = lastId + 1
          }
          else {
            this.gpathRoadmapExtraObjList = []
            gpathRoadmapExtraAddData.id = 11111
          }

          gpathRoadmapExtraAddData.created_on = moment()
          this.gpathRoadmapExtraObjList.unshift(gpathRoadmapExtraAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathrRoadmapExtraAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpathRoadmapExtraAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathrRoadmapExtraEditModal
     */
    closeGPathrRoadmapExtraEditModal () {
      try {
        this.showModelGPathrRoadmapExtraEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathrRoadmapExtraEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
